import classNames from "classnames"
import { HeaderMain } from "../../components/layout"
import { useLocation, useSearchParams } from "react-router-dom"
import { useEffect } from "react"

export const BetLabTerms = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const routePath = useLocation()
  const onTop = () => {
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    onTop()
  }, [routePath])

  const getTodayDate = (isTime = false) => {
    const dateobj = new Date()
    const y = dateobj.getFullYear()
    const m = dateobj.getMonth() + 1
    const d = dateobj.getDate()
    const time =
      dateobj.getHours() +
      ":" +
      dateobj.getMinutes() +
      ":" +
      dateobj.getSeconds()
    const date = [d, m, y].join("/")
    if (isTime) {
      return `${date} ${time}`
    }
    return date
  }

  return (
    <>
      <HeaderMain />
      <main className={"container"}>
        <div className={classNames("text-center", "heading-section")}>
          <h3 className="main-heading" autoFocus>
            Betlab Market Research Corp.Acknowledgement
          </h3>
        </div>
        <div className="responsible-section">
          <p>
            <strong>DATED:</strong>
            <span style={{ marginLeft: 16 }}>{getTodayDate()}</span>
          </p>
          <p>
            <strong>RE:</strong>
            <span style={{ marginLeft: 16 }}>
              Participation in BetLab Market Research Surveys & Consumer Insight
              Studies
            </span>
          </p>
          <p>
            This agreement ("Agreement") is made and entered into as of{" "}
            {getTodayDate(true)} (“Signing Date”) by and between BetLab Market
            Research Corp. ("BetLab") and{" "}
            <strong>{searchParams.get("fname")}</strong>{" "}
            <strong>{searchParams.get("lname")} </strong>
            ("Client"). BetLab and the Client are each a “Party” and together
            the “Parties”. As a condition of participating in any BetLab market
            research surveys (“Surveys”) and consumer insight studies
            (“Studies”), the Client acknowledges, confirms, and agrees with
            BetLab’s conditions set forth below:
          </p>
          <h3>A. SCOPE OF SERVICES</h3>
          <p style={{ padding: 16 }}>
            1. BetLab conducts Surveys and Studies for the iGaming industry in
            North America. The purpose of the Surveys and Studies is to collect
            relevant data from real world consumers, which is then used to
            create reports that are sold to private enterprises in Canada and
            the United States (“<strong>Reports</strong>”). By completing a
            Survey, the Client agrees to provide data on a best effort basis,
            which reflects their experience placing a real money wager with one
            or more online casino operators (“<strong>Services</strong>”). The
            Client may be asked within the Survey to provide personal
            identifying information (“<strong>Personal Data</strong>”). All
            Personal Data is collected solely for the purpose of authenticating
            the validity and accuracy of the data. Reports generated by BetLab
            will only contain anonymized data. Reports will not contain any of
            the Client’s Personal Data. The Client agrees provide these Services
            as an independent contractor and not as a director, officer,
            representative of BetLab. For the avoidance of doubt, the Client
            Client may not bind BetLab.{" "}
          </p>
          <h3>B. REMUNERATION</h3>
          <p style={{ padding: 16 }}>
            1. In respect of delivery of the Services as set out above, the
            Client shall be paid as follows.
          </p>
          <p style={{ padding: 18 }}>
            (i) A fixed or variable amount of money, or cash equivalent, for the
            client’s completion of the Survey. Amounts will be pre-determined
            between BetLab and the Client prior to the Client’s completion of
            the Survey. Renumeration amounts may vary depending on the scope of
            the work involved and will be at the sole discretion of BetLab.
          </p>
          <p style={{ padding: 18 }}>
            (ii) BetLab may pay Clients by e-transfer, cash, gift cards, and
            other methods which are consistent with companies that perform data
            collection services and on-premises activations. (the “
            <strong>Survey Fees</strong>”)
            <br />
            For the avoidance of doubt, the Client is being paid Survey Fees
            SOLELY FOR THEIR TIME AND EFFORT TO COMPLETE THE SURVEY. IF A
            CLIENT’S FAILS TO COMPLETE THE SURVEY IN FULL THEY MAY NOT BE
            COMPENSATED FOR THEIR TIME AND EFFORT.
          </p>
          <h3>C. CONFIDENTIAL INFORMATION</h3>
          <p style={{ padding: 16 }}>
            1. The Client recognizes that in performing the responsibilities
            under this Agreement they may have access to and will be exposed to
            Confidential Information (as hereinafter defined) and that the
            Confidential Information is a unique asset of BetLab, and its
            affiliated or associated companies (hereafter collectively,
            “BetLab”), developed and perfected over a considerable time and at
            substantial expense to BetLab, and that the improper disclosure of
            such Confidential Information may cause injury, loss of profits and
            loss of goodwill to BetLab. All Confidential Information which the
            Clients receives, conceives or develops, either alone or with
            others, in providing the Services shall be the exclusive property of
            BetLab. Except as BetLab may authorize in writing, the Client shall
            not directly or indirectly: (i) disclose such Confidential
            Information to any third party; (ii) use such Confidential
            Information in your own business, or; (iii) otherwise use any
            Confidential Information for any purpose whatsoever other than is
            required in the performance of Services for BetLab. You agree to use
            all reasonable precautions to ensure that all Confidential
            Information is properly protected from improper use or disclosure
            using at least the level of protection you use to safeguard your own
            confidential information.
          </p>
          <p style={{ padding: 16 }}>
            1. The Client recognizes that in performing the responsibilities
            under this Agreement they may have access to and will be exposed to
            Confidential Information (as hereinafter defined) and that the
            Confidential Information is a unique asset of BetLab, and its
            affiliated or associated companies (hereafter collectively,
            “BetLab”), developed and perfected over a considerable time and at
            substantial expense to BetLab, and that the improper disclosure of
            such Confidential Information may cause injury, loss of profits and
            loss of goodwill to BetLab. All Confidential Information which the
            Clients receives, conceives or develops, either alone or with
            others, in providing the Services shall be the exclusive property of
            BetLab. Except as BetLab may authorize in writing, the Client shall
            not directly or indirectly: (i) disclose such Confidential
            Information to any third party; (ii) use such Confidential
            Information in your own business, or; (iii) otherwise use any
            Confidential Information for any purpose whatsoever other than is
            required in the performance of Services for BetLab. You agree to use
            all reasonable precautions to ensure that all Confidential
            Information is properly protected from improper use or disclosure
            using at least the level of protection you use to safeguard your own
            confidential information.
          </p>
          <p style={{ padding: 16 }}>
            2. Upon completion or other termination of the Services, or upon
            BetLab’s request, the Client shall deliver to BetLab all documents,
            papers, samples, reports, notes, notebooks, memoranda, discs,
            electronically-stored files and data, software, printouts and other
            similar repositories of information in any form or format containing
            or relating to Confidential Information in the Client’s possession.
            The Client acknowledge and agree not to publish any document or
            paper relating to BetLab without the prior written approval of the
            C.E.O.
          </p>
          <p>
            “<strong>Confidential Information</strong>” for the purposes of this
            Agreement means, without limitation, survey data, compensation
            amounts for Services provided, financial and business plans,
            calculations, concepts, designs, data, systems, databases, computer
            programs, software, copyrights, trademarks, trademark applications,
            patents, patent applications and any and all other intellectual
            property whatsoever in any form or medium belonging to BetLab, or
            being used by BetLab, or any of its customers, source code, screen
            designs and input and output specifications, manuals, formulas,
            drawings, processes, instructions, research, compensation policies
            or information, identity and description of computerized records,
            customer lists, customer and supplier pricing policies, marketing
            and sales plans, financial information, costs and all other concepts
            or ideas, involving or reasonably related to the business or
            prospective business of BetLab, whether marked confidential or not,
            and which is not generally available to the public.
          </p>
          <h3>D. OWNERSHIP OF INTELLECTUAL PROPERTY</h3>
          <p style={{ padding: 16 }}>
            1. All Surveys and Studies completed by the Client (“Deliverables”),
            and all data contained within, shall be the sole and exclusive
            property of BetLab. The Client hereby assigns and agrees to assign
            to BetLab all right, title, and interest they may have in the
            Deliverables and any other materials and information prepared by the
            Client in providing the Services. Client hereby waive all moral
            rights in the Deliverables.
          </p>
          <h3>E. ASSIGNMENT</h3>
          <p style={{ padding: 16 }}>
            1. The Client may not assign or otherwise transfer this Agreement,
            in whole or in part, without the prior written consent of BetLab.
            BetLab may assign this Agreement to a third party in its sole
            discretion without prior consent of the Client.
          </p>
          <h3>F. CLIENT ACKNOWLEDGEMENT AND AGREEMENT</h3>
          <p style={{ padding: 16 }}>
            The Client acknowledges and agrees to the following:
          </p>
          <p style={{ padding: 16 }}>1. I am at least nineteen years of age.</p>
          <p style={{ padding: 16 }}>
            2. have the legal capacity to complete this Acknowledgement.
          </p>
          <p style={{ padding: 16 }}>
            3. I recognize that I am being compensated by BetLab exclusively for
            the completion of a Survey, and the information I provide therein,
            and will not receive compensation without completion of the Survey.
          </p>
          <p style={{ padding: 16 }}>
            4. BetLab owns, and has the exclusive right to use, market, sell or
            otherwise deal with, the data derived from my Survey in any manner
            in its sole discretion.
          </p>
          <p style={{ padding: 16 }}>
            5. As part of the paid Services I provided BetLab, I consent to
            receiving communications by text and e-mail in relation to the
            Survey I complete, the Study I was involved in, and future Surveys
            and Studies offered by BetLab.
          </p>
          <p style={{ padding: 16 }}>
            6. BetLab may use third parties’ services to assist in the
            collection of the Surveys and the issuance of payment.{" "}
          </p>
          <p style={{ padding: 16 }}>
            7. I recognize that registering for an online casino or sportsbook
            and placing a wager may be required in order for BetLab to verify
            the authenticity of my Survey. Failure to provide evidence of my
            registration, satisfactory to BetLab in its sole discretion, may
            result in me not receiving any compensation from BetLab. 
          </p>
          <p style={{ padding: 16 }}>
            8. I agree to update BetLab immediately in the event I learn that
            any of the above statements are untrue at any time in the future.{" "}
          </p>

          <h3>G. GENERAL TERMS</h3>
          <p style={{ padding: 16 }}>
            1. If any provision of this Agreement is for any reason invalid,
            that provision shall be considered separate and severable from this
            Agreement, and the other provisions of this Agreement shall remain
            in force and continue to be binding upon the parties as though the
            invalid provision had never been included in this Agreement.
          </p>
          <p style={{ padding: 16 }}>
            2. This Agreement is to be governed by and construed according to
            the laws of the Province of Ontario, and the laws of Canada
            applicable therein, and each of the parties hereto hereby
            irrevocably attorns to the non-exclusive jurisdiction of the courts
            thereof.
          </p>
          <p style={{ padding: 12 }}>
            3. This Agreement is binding when signed by both parties and may be
            signed in counterparts and by electronic signatures, which are
            hereby deemed original signatures.
          </p>
          <p>
            I have read, understood and agreed to the terms and conditions of
            this Agreement as of the day set forth above.
          </p>
          <div style={{ textAlign: "right" }}>
            <p style={{ margin: 0 }}>{searchParams.get("responseId")}</p>
            <p>------------</p>
            <p>
              <strong>
                Name : {searchParams.get("fname")} {searchParams.get("lname")}{" "}
              </strong>
            </p>
            <p>
              <strong>Date : {getTodayDate()} </strong>
            </p>
          </div>
        </div>
      </main>
    </>
  )
}
