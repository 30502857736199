export const Types = [
  {
    slug: "casino",
    label: "CASINO",
  },
  {
    slug: "sports",
    label: "SPORTSBOOK",
  },
]
