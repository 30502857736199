import {
  Link as NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom"
import style from "./footer.module.scss"
import classNames from "classnames"
import Plus19Logo from "../../../assets/images/plus19.svg"
import ConnexLogo from "../../../assets/images/connexLogo.svg"
import ProblemGambling from "../../../assets/images/ProblemGambling.png"
import { useCanParams } from "../../../hooks/useCanParams"

export const FooterMain = () => {
  const { provider } = useCanParams()
  const [ba] = useSearchParams()
  let provider1 = ""
  const { pathname: Routeparam } = useLocation()
  if (ba.get("ba")) {
    provider1 = ba.get("ba") == "orbet" || "sixdot" ? "orbet" : ""
  }
  const providers = ["orbet", "sixdot"]
  return (
    <div id="footer">
      <footer className={classNames(style.footer)}>
        <div className={"container"}>
          <div className={"footer-wrapper"}>
            <div className="col-xs-12">
              <p>
                PLAYCAN{" "}
                {providers.includes(provider) || providers.includes(provider1)
                  ? "2024"
                  : "2023"}{" "}
                All Rights Reserved
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <NavLink to={`/responsible-gambilng?ba=${provider}`}>
                    Responsible Gambling
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <Plus19Logo />
              {providers.includes(provider) || providers.includes(provider1) ? (
                <img
                  width="300px"
                  src={ProblemGambling}
                  alt="ProblemGambling"
                  style={{ filter: "drop-shadow(0px 0px 0px #fff)" }}
                />
              ) : (
                <ConnexLogo width="300px" />
              )}
            </div>
          </div>
        </div>
        <div className="disclaimer">
          <p>
            <span>Disclaimer</span>
          </p>
          <i>
            {providers.includes(provider) || providers.includes(provider1)
              ? "Must be 19+ and physically present in Canada to place wagers (excluding Ontario). Ontario residents are not permitted on this site. If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER to speak with someone and seek assistance."
              : "Must be 19+ and physically present in Ontario to place wagers. If you or someone you know has a gambling problem, help is available. www.connexontario.ca or call ConnexOntario at 1-866-531-2600."}
          </i>
        </div>
      </footer>
      {(Routeparam == "/responsible-gambilng" ||
        (provider && provider !== "playcan")) && (
        <div className={style.survey_container}>
          <div className={style.survey_btn}>
            <NavLink to="/survey">Survey</NavLink>
          </div>
        </div>
      )}
    </div>
  )
}
