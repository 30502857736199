import { useParams } from "react-router-dom"
import { useHeadingSpan } from "../../hooks/useHeading"

interface HeadingProps {
  state?: string
  type?: string
}

export const Heading = ({ state, type }: HeadingProps) => {
  const span = useHeadingSpan(type ? type : state, type !== undefined)
  return (
    <div className="text-center heading-section">
      <h4 className="sub-heading">CURATED OFFERS FROM</h4>
      <h1 className="main-heading">
        CANADA’S TOP <span>{span}</span>
      </h1>
    </div>
  )
}
