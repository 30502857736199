import { NavLink } from "react-router-dom"
import { Types as typesList } from "../../types/Types"
import { useState } from "react"
interface StatesProps {
  provider: string
  state?: string
}

export const Types = ({ state, provider }: StatesProps) => {
  const tolink =
    provider === "playcan"
      ? `/${state}`
      : state
      ? `/${provider}/${state}`
      : `/${provider}`

  return (
    <>
      <div className="location-options">
        <div>
          <div className="text-center ">
            <h4 className="sub-heading-location">Choose An Option Below</h4>
          </div>
          <div className="btns">
            {typesList.map((type) => {
              return (
                <div className="option">
                  <NavLink to={`${tolink}/${type.slug}`} key={type.slug}>
                    {type.label}
                  </NavLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
