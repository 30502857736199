import classNames from "classnames"
import "./App.scss"
import { routes } from "./routes"
import {
  useLocation,
  useParams,
  useRoutes,
  useSearchParams,
} from "react-router-dom"
import { useEffect } from "react"

function App() {
  const content = useRoutes(routes)
  const [searchParams] = useSearchParams()
  const url = searchParams.get("url")
  if (url) {
    let stringUrl = atob(url)
    const elementBoday = document.getElementsByTagName("body")
    elementBoday[0].style.backgroundImage = "none"
    elementBoday[0].style.backgroundColor = "#fff"
    window.location.href = stringUrl
    return <>Securely Redirecting ...</>
  }
  return <div className={classNames("App")}>{content}</div>
}

export default App
