import { CansList } from "../../components/cans/CansList"
import { Heading } from "../../components/layout/Heading"

interface ListProps {
  provider?: string
  state?: string
  type?: string
  cans: any[]
}

export const List = ({ provider, state, type, cans }: ListProps) => {
  return (
    <>
      <Heading type={type} state={state} />
      <div className="casino">
        <CansList
          type={type}
          state={state}
          cans={cans}
          provider={provider}
        />{" "}
        <div className="disclaimer mainDisclaimer">
          <p>
            <span>Affiliate Disclosure:</span> PlayCan may receive advertising
            commissions for visits to a sportsbook or casino betting site. 19+ Gambling can be addictive, please play responsibly.
          </p>
        </div>
      </div>
    </>
  )
}
