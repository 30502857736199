import { HeaderMain } from "./header/HeaderMain"
import { FooterMain } from "./footer/FooterMain"

interface BaseProps extends React.PropsWithChildren {}

export const Base = ({ children, ...props }: BaseProps) => {
  return (
    <>
      <HeaderMain />
      <main className={"container"}>{children}</main>
      <FooterMain />
    </>
  )
}
