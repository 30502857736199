import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { cansApi } from "../components/cans/cansApi"
import { providersApi } from "../components/providers/providersApi"
import { setupListeners } from "@reduxjs/toolkit/query"
import { surveyApi } from "../components/survey/surveyApi"
export const store = configureStore({
  reducer: {
    [cansApi.reducerPath]: cansApi.reducer,
    [providersApi.reducerPath]: providersApi.reducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      cansApi.middleware,
      providersApi.middleware,
      surveyApi.middleware,
    ),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
