const getHeadingSpanByType = (type: string) => {
  return type === "casino" ? "CASINOS" : "SPORTSBOOKS"
}

const getHeadingSpanByState = (state: string) => {
  return "CASINOS"
}

export const useHeadingSpan = (key: string | undefined, isType: boolean) => {
  if (!key) return "CASINOS"
  if (isType) {
    return getHeadingSpanByType(key)
  }
  return getHeadingSpanByState(key)
}
