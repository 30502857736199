import { useGetProviderSingleQuery } from "../components/providers/providersApi"

interface MenuType {
  [key: string]: any
}

const menu: MenuType = { sports: "sportsbooks", casino: "casinos" }

export const useMenu = (
  state: string | undefined,
  type: string | undefined,
  provider: string | undefined,
) => {
  if (!provider) {
    provider = "playcan"
  }
  const { data, isSuccess } = useGetProviderSingleQuery({
    slug: provider,
    type,
    state,
  })

  if (!state || !type) return false
  if (isSuccess) {
    if (!data.status) return false
    const cans: any = data.cans.filter((item: any) => {
      if (item.state)
        if (
          item.state.slug == state &&
          item.type == type.toUpperCase() &&
          item.status
        ) {
          return true
        }
    })
    if (cans.length <= 0) return false
    cans.sort(
      (a: { sortOrder: number }, b: { sortOrder: number }) =>
        a.sortOrder - b.sortOrder,
    )
    return { label: menu[type], children: cans }
  }

  return false
}
