import { Base } from "./layout"
import { useCanParams } from "../hooks/useCanParams"
import { useGetProviderSingleQuery } from "./providers/providersApi"
import { List } from "../pages/list/List"
import { States } from "../pages/states/States"
import { Types } from "../pages/types/Types"
import { Bars } from "react-loader-spinner"
export const Content = () => {
  const { provider, type, state, isSuccess, providerStatus } = useCanParams()

  return (
    <Base>
      {isSuccess ? (
        providerStatus ? (
          <Switcher provider={provider} state={state} type={type} />
        ) : (
          <DisabelProvider />
        )
      ) : (
        <></>
      )}
    </Base>
  )
}

export const DisabelProvider = () => {
  return (
    <>
      <div className="location-options responsible-section">
        <div>
          <div className="container text-center ">
            <h4 className="sub-heading-location">Welcome to PlayCan!</h4>
            <p>This page is not available at the moment</p>
          </div>
        </div>
      </div>
    </>
  )
}

interface SwitcherProps {
  type?: string
  state?: string
  provider: string
}

export const Switcher = ({ provider, type, state }: SwitcherProps) => {
  const { data, isSuccess } = useGetProviderSingleQuery({
    slug: provider,
    type,
    state,
  })

  if (isSuccess) {
    switch (true) {
      case data.CanStates.length > 0 && !state:
        return (
          <>
            <States provider={provider} states={data.CanStates} />
          </>
        )
      case data.hasTypes && !type:
        return (
          <>
            <Types provider={provider} state={state} />
          </>
        )
      default:
        return (
          <>
            <List
              provider={provider}
              state={state}
              type={type}
              cans={data.cans}
            />
          </>
        )
    }
  }
  return (
    <>
      <Bars
        height="50"
        width="50"
        color="#B12932"
        ariaLabel="bars-loading"
        wrapperStyle={{
          textAlign: "center",
          display: "block",
          margin: "auto",
        }}
        wrapperClass=""
        visible={true}
      />
    </>
  )
}
