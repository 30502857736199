import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useGetProvidersListQuery } from "../components/providers/providersApi"

export const useCanParams = () => {
  let { first, second, third } = useParams()

  const [provider, setProvider] = useState<string>("")
  const [state, setState] = useState<string | undefined>("")
  const [type, setType] = useState<string | undefined>("")
  const [providerStatus, setProviderStatus] = useState<boolean | undefined>(
    true,
  )
  first = first ? first.toLocaleLowerCase() : undefined
  second = second ? second.toLocaleLowerCase() : undefined
  third = third ? third.toLocaleLowerCase() : undefined
  const { data: providers, isSuccess } = useGetProvidersListQuery({
    slug: first,
  })

  useEffect(() => {
    if (isSuccess) {
      const provider = providers?.data?.find((p: any) => p.slug === first)
      if (provider) {
        setProvider(provider.slug)
        setState(second || undefined)
        setType(third || undefined)
        setProviderStatus(provider.status)
      } else {
        setProvider("playcan")
        setState(first || undefined)
        setType(second || undefined)
        setProviderStatus(true)
      }
    }
  }, [isSuccess, providers, first, second, third])

  return {
    provider,
    state,
    type,
    isSuccess,
    first,
    second,
    third,
    providerStatus,
  }
}
