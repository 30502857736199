import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export interface ServerResponse<T> {
  statusCode: number
  message: string
  data: T
}

export const surveyApi = createApi({
  reducerPath: "surveyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_SERVER_URL,
  }),
  tagTypes: ["Survey"],
  endpoints: (builder) => ({
    getSingleSurvey: builder.query<any, number>({
      query: (id) => {
        return {
          url: `/user/survey/${id}`,
        }
      },
    }),
    saveSurvey: builder.mutation<ServerResponse<any>, any>({
      query: (body) => {
        return {
          url: `/user/survey/save`,
          method: "POST",
          body,
          //formData: true,
        }
      },
      invalidatesTags: ["Survey"],
      transformResponse: (response: ServerResponse<any>) => response,
    }),
  }),
})

export const { useSaveSurveyMutation, useGetSingleSurveyQuery } = surveyApi
