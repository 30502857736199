import { Suspense, lazy } from "react"
import type { RouteObject } from "react-router"

import { Content } from "./components/Content"
import Survey from "./pages/survey/Survey"
import { BetLabTerms } from "./pages/betlabterms/BetLabTerms"
import SurveyPDF from "./pages/survey/SurveyPDF"
import BetlabRedirect from "./components/BetlabRedirect"

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  )

const Home = Loadable(lazy(() => import("./pages/home/Home")))
const ResponsibleGambling = Loadable(
  lazy(() => import("./pages/responsible-gambling/ResponsibleGambling")),
)

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/:first?/:second?/:third?",
    element: <Content />,
  },
  {
    path: "/redirect",
    element: <BetlabRedirect />,
  },
  {
    path: "/responsible-gambilng",
    element: <ResponsibleGambling />,
  },
  // {
  //   path: "/survey",
  //   element: <Survey />,
  // },
  // {
  //   path: "/survey/:id",
  //   element: <SurveyPDF />,
  // },
  {
    path: "/terms",
    element: <BetLabTerms />,
  },
]
