import Rating from "./Rating"

interface RatingProps {
  value: number
}

export const OurRating = ({ value }: RatingProps) => {
  return (
    <>
      {value > 0 && (
        <div className="rating-box">
          <div className="first_element">
            <p className="rate_haeding">OUR RATING :</p>
            <p className="rate">{value}/5</p>
          </div>
          <div className="second_element">
            <Rating count={5} value={value} />
          </div>
        </div>
      )}
    </>
  )
}
