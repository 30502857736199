import Rating from "../rating/Rating"
import AppleIcon from "../../assets/images/apple.svg"

interface RatingProps {
  value: number
}

export const AppStoreRating = ({ value }: RatingProps) => {
  return (
    <>
      {value > 0 && (
        <div className="rating-box">
          <div className="first_element">
            <p className="rate_haeding">
              <AppleIcon />
              &nbsp;AppStore Rating:
            </p>
            <p className="rate">{value}/5</p>
          </div>
          <div className="second_element">
            <Rating count={5} value={value} />
          </div>
        </div>
      )}
    </>
  )
}
