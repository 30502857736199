import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Provider } from "../../types/Provider"
interface GetSingleProviderParams {
  slug: string
  type?: any
  state?: any
}
interface GetProviderListParams {
  slug?: string
  hasTypes?: any
  status?: any
}

export const providersApi = createApi({
  reducerPath: "providersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_SERVER_URL,
  }),
  endpoints: (builder) => ({
    getProvidersList: builder.query<any, GetProviderListParams>({
      query: ({ slug }) => {
        return {
          url: `providers?slug=${slug}`,
        }
      },
    }),
    getProviderSingle: builder.query<Provider, GetSingleProviderParams>({
      query: ({
        slug,
        type = false,
        state = false,
      }: GetSingleProviderParams) => {
        return {
          url: `providers/single/${slug}`,
          method: "POST",
          body: {
            state,
            type,
          },
        }
      },
    }),
  }),
})

export const { useGetProvidersListQuery, useGetProviderSingleQuery } =
  providersApi
