import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import type { Can } from "../../types/Can"

type GetCansParams = {
  type?: string
  state: string
}
export const cansApi = createApi({
  reducerPath: "cansApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_SERVER_URL,
  }),
  endpoints: (builder) => ({
    getCansList: builder.query<Can[], GetCansParams>({
      query: (params) => {
        return {
          url: `cans`,
          params,
        }
      },
    }),
  }),
})

export const { useGetCansListQuery } = cansApi
